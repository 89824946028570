import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Html from 'Component/Html';

import { ReactComponent as CloseBtn } from './assets/close-black.svg';

import './PromoPopup.style';

/** @namespace Pwa/Component/PromoPopup/Component/PromoPopupComponent */
export class PromoPopupComponent extends PureComponent {
    static propTypes = {
        popupContent: PropTypes.string.isRequired,
        topContent: PropTypes.string.isRequired,
        hidePopup: PropTypes.func.isRequired,
        isPopupShown: PropTypes.bool.isRequired,
        isWhiteList: PropTypes.bool.isRequired,
        topBlockDolceCasaContent: PropTypes.string.isRequired
    };

    render() {
        const {
            popupContent,
            topContent,
            hidePopup,
            isPopupShown,
            topBlockDolceCasaContent,
            isWhiteList
        } = this.props;

        return (
            <div block="PromoPopup">
                { topContent && !(isWhiteList && topBlockDolceCasaContent) && (
                    <div block="PromoPopup" elem="Top">
                        <Html content={ topContent } />
                    </div>
                ) }
                { topBlockDolceCasaContent && isWhiteList && (
                    <div block="PromoPopup" elem="Top">
                        <Html content={ topBlockDolceCasaContent } />
                    </div>
                ) }
                { popupContent && isPopupShown && (
                    <div block="PromoPopup" elem="SidePopup">
                        <button
                          aria-label={ __('Close') }
                          onClick={ hidePopup }
                          block="PromoPopup"
                          elem="CloseBtn"
                        >
                            <CloseBtn />
                        </button>
                        <Html content={ popupContent } />
                    </div>
                ) }
            </div>
        );
    }
}

export default PromoPopupComponent;
