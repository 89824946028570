export const EDIT_ADDRESS_POPUP = 'EDIT_ADDRESS_POPUP';
// eslint-disable-next-line no-magic-numbers, max-len
export const CATEGORY_ID_ANGOLO_DELLE_OCCASIONI = (window.location.hostname === 'staging2.mondoconv.it') ? 3268 : 3230;
export const PAGE_SIZE = 50;
export const ID_POPUP = 'POPUP_PRICE_ADO';
export const ADO_AVAILABILITY_POPUP = 'ADO_AVAILABILITY_POPUP';
export const CARTE_PAGE_SIDE_POPUP_ADO = 'CARTE_PAGE_SIDE_POPUP_ADO';
export const CARTE_PAGE_WAREHOUSE_POPUP_ADO = 'CARTE_PAGE_WAREHOUSE_POPUP_ADO';
export const QUANTITA_ADO_API = '/rest/V1/mc/angolodelleoccasioni/getquantity/';
export const ORDER_BY_OVERLAY = 'ORDER_BY_OVERLAY';
export const FILTER_BY_OVERLAY = 'FILTER_BY_OVERLAY';
export const ORDER_BY_TOGGLE = 'ORDER_BY_TOGGLE';
export const LOWER_PRICE_FILTER = 'lprice';
export const HIGH_PRICE_FILTER = 'hprice';
export const QTY_PRICE_FILTER = 'qprice';
export const NAME_PRICE_FILTER = 'nprice';
