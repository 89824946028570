/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    McMenuScrollFreezeContainer
} from 'Component/McMenuScrollFreeze/McMenuScrollFreeze.container';
import { resetCurrentMcMenuItem } from 'Store/McMenu/McMenu.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { McMenuNodeType } from 'Type/McMenuType';

import McMenuFullScreen from './McMenuFullScreen.component';

/** @namespace Pwa/Component/McMenuFullScreen/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    currentMenuItem: state.McMenuReducer.currentItem,
    flagConfigurator: state.McMenuReducer.flagConfigurator,
    collections: state.McMenuReducer.collections,
    flagConfiguratorChair: state.McMenuReducer.flagConfiguratorChair
});

/** @namespace Pwa/Component/McMenuFullScreen/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    resetCurrentItem: () => dispatch(resetCurrentMcMenuItem()),
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Pwa/Component/McMenuFullScreen/Container/McMenuFullScreenContainer */
export class McMenuFullScreenContainer extends McMenuScrollFreezeContainer {
    static propTypes = {
        data: McMenuNodeType.isRequired, // Whole menu structure
        currentMenuItem: PropTypes.string,
        resetCurrentItem: PropTypes.func.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    static defaultProps = {
        currentMenuItem: null
    };

    containerFunctions = {
        onSubCategory: this.onSubCategory.bind(this),
        onCloseHandler: this.onCloseHandler.bind(this),
        onHideOverlay: this.onHideOverlay.bind(this),
        onVisibleOverlay: this.onVisibleOverlay.bind(this),
        subCategoryClick: this.subCategoryClick.bind(this)
    };

    containerProps = () => {
        const {
            data, flagConfigurator, collections, flagConfiguratorChair
        } = this.props;
        const { category } = this.state;
        const { isSubCategoryClicked, subCategoryClicked } = this.state;

        return {
            category,
            data,
            isSubCategoryClicked,
            subCategoryClicked,
            flagConfigurator,
            collections,
            flagConfiguratorChair
        };
    };

    __construct(props) {
        super.__construct(props);

        const { data } = this.props;
        this.state = {
            category: data, // Preset menu with root element
            isSubCategoryClicked: false,
            subCategoryClicked: ''
        };
    }

    subCategoryClick(e) {
        const { isSubCategoryClicked, subCategoryClicked } = this.state;
        if (!(e == null && e === undefined) && e.hasOwnProperty('currentTarget') && (e.currentTarget.id !== 'mclist-back-arrow')) {
            const subCategoryClickedNew = String(e.currentTarget.firstChild.data).toLowerCase().trim();
            this.setState({ subCategoryClicked: subCategoryClickedNew });
            if (isSubCategoryClicked === true && subCategoryClicked !== '' && subCategoryClickedNew !== subCategoryClicked) {
                return;
            }
            this.setState({
                isSubCategoryClicked: !isSubCategoryClicked
            });
        } else {
            this.setState({ subCategoryClicked: '' });
            this.setState({
                isSubCategoryClicked: !isSubCategoryClicked
            });
        }
    }

    onSubCategory(category) {
        this.setState({ category });
    }

    checkCurrentMenu() {
        const { data: { path }, currentMenuItem } = this.props;
        return (path === currentMenuItem);
    }

    onCloseHandler() {
        const { resetCurrentItem, hideActiveOverlay, currentScroll: { active: scrollStatus } } = this.props;

        resetCurrentItem();
        hideActiveOverlay();
        if (scrollStatus) {
            this.unfreezeScroll();
        }
    }

    onVisibleOverlay() {
        const { currentScroll: { active: scrollStatus } } = this.props;

        if (!scrollStatus) {
            this.freezeScroll();
        }
    }

    onHideOverlay() {
        const { resetCurrentItem, currentScroll: { active: scrollStatus } } = this.props;

        if (this.checkCurrentMenu()) {
            resetCurrentItem();
            if (scrollStatus) {
                this.unfreezeScroll();
            }
        }
    }

    render() {
        return (
            <McMenuFullScreen
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(McMenuFullScreenContainer);
