/* eslint-disable no-magic-numbers */
/* eslint-disable eqeqeq */
/* eslint-disable max-lines */
import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';

export const MAX_COLOR_LIMIT = 5;

/** @namespace Pwa/Query/ProductList/Query/ProductListQuery */
export class ProductListQuery extends SourceProductListQuery {
    getQuery(options) {
        const newOptions = {
            ...options
        };

        // Fix sorting in search
        if (newOptions.args?.search
            && newOptions.args?.sort?.sortDirection === 'ASC'
            && newOptions.args?.sort?.sortKey === 'position'
            && newOptions.exceptionCat !== 'collezioni'
        ) {
            newOptions.args.sort.sortDirection = 'DESC';
            newOptions.args.sort.sortKey = 'relevance';
        }

        return super.getQuery(newOptions);
    }

    _getFilterArgumentMap() {
        return {
            ...super._getFilterArgumentMap(),
            flashsale: (code) => ({ flashsale: { eq: code } }),
            mc_black_friday_counter: (code) => ({ mc_black_friday_counter: { eq: code } })
        };
    }

    _getColorInformationField() {
        return new Field('mc_color_information')
            .addFieldList([
                'description',
                'image_url',
                'big_image_url'
            ]);
    }

    _getFinitureColoriInformationField() {
        return new Field('mc_finiture_colori_information')
            .addFieldList([
                'code',
                'label',
                'image',
                'codice_colore'
            ]);
    }

    _getTipologieCucineInformationField() {
        return new Field('mc_tipologie_cucine_information')
            .addFieldList([
                'code',
                'label',
                'image'
            ]);
    }

    _getBoxMosaicoField() {
        const singleMosaico = [
            'type',
            'value'
        ];

        return new Field('mc_box_mosaico')
            .addField(new Field('mosaico_top_left').addFieldList(singleMosaico))
            .addField(new Field('mosaico_bottom_left').addFieldList(singleMosaico))
            .addField(new Field('mosaico_top_center').addFieldList(singleMosaico))
            .addField(new Field('mosaico_bottom_center').addFieldList(singleMosaico))
            .addField(new Field('mosaico_top_right').addFieldList(singleMosaico))
            .addField(new Field('mosaico_bottom_right').addFieldList(singleMosaico));
    }

    _getColoriDisponibiliField() {
        return new Field('colori_disponibili')
            .addFieldList([
                'label',
                'hex'
            ]);
    }

    _getManiglieInformationField() {
        return new Field('mc_maniglie_information')
            .addFieldList([
                'code',
                'label',
                'descrizione',
                this._getColoriDisponibiliField(),
                'image'
            ]);
    }

    _getPianiDiLavoroInformationField() {
        return new Field('mc_piani_di_lavoro_information')
            .addFieldList([
                'code',
                'label',
                'tipologiaPiano',
                'descrizione',
                'image'
            ]);
    }

    _getElettrodomesticiInformationField() {
        return new Field('mc_elettrodomestici_information')
            .addFieldList([
                'code',
                'label',
                'categoria',
                'imageCategoria',
                'link',
                'image',
                this._getMarchioField()
            ]);
    }

    _getMarchioField() {
        return new Field('marchio')
            .addFieldList([
                'code',
                'label',
                'image'
            ]);
    }

    _getMeccanismiInformationField() {
        return new Field('mc_meccanismi_information')
            .addFieldList([
                'code',
                'label',
                'descrizione',
                'image'
            ]);
    }

    _getTessutiColoriInformationField() {
        return new Field('mc_tessuti_colori_information')
            .addFieldList([
                'code',
                'label',
                'image',
                'codice_colore'
            ]);
    }

    _getComplementiInformationField() {
        return new Field('mc_complementi_information')
            .addFieldList([
                'code',
                'label',
                'link',
                'image'
            ]);
    }

    _getCollezioneCamereInformationField() {
        return new Field('mc_collezione_camere_information')
            .addFieldList([
                'label',
                'image',
                'url'
            ]);
    }

    _getCamereCompleteInformationField() {
        return new Field('mc_camere_complete_information')
            .addFieldList([
                'label',
                'image',
                'link'
            ]);
    }

    _getElettrodomesticiBloccatiInformationField() {
        return new Field('mc_elettrodomestici_bloccati_information')
            .addFieldList([
                'label',
                'image',
                'link'
            ]);
    }

    _getSidAbbinatiField() {
        return new Field('mc_sid_abbinati')
            .addFieldList([
                'url',
                'label'
            ]);
    }

    _getDishWasherInformationField() {
        return new Field('mc_dishwasher_variant_information')
            .addFieldList([
                'link',
                'has_dishwasher'
            ]);
    }

    _getComponentiCamereInformationField() {
        return new Field('mc_componenti_camere_information')
            .addFieldList([
                'code',
                'label',
                'image',
                'imageCategoria',
                'categoria',
                'mish',
                'misl',
                'misp',
                this._getSidAssociatiField()
            ]);
    }

    _getVersioniProdottoInformationField() {
        return new Field('mc_versioni_prodotto_information')
            .addFieldList([
                'label',
                'image',
                'link'
            ]);
    }

    _getInterniArmadiInformationField() {
        return new Field('closet_related')
            .addFieldList([
                'label',
                'url'
            ]);
    }

    _getVersioniProdottoTwoInformationField() {
        return new Field('mc_versioni_prodotto_information_two')
            .addFieldList([
                'sku',
                'name',
                'url',
                'mc_inline_description',
                'mc_dimension_width',
                'mc_dimension_height',
                'mc_dimension_depth',
                'mc_vendibile_sito',
                'varianttag_sorting',
                this._getColorInformationField(),
                this._getPriceRangeField(),
                this._getProductSmallField(),
                this._getDiscountImageField(),
                this._getBlackFridayCounterField(),
                this._getStickersImageField(),
                this._getProductListingImagesField()
            ]);
    }

    _getModelliInformationField() {
        return new Field('mc_modelli_information')
            .addFieldList([
                'code',
                'label'
            ]);
    }

    _getArmadiNeutriInformationField() {
        return new Field('mc_armadi_neutri_information')
            .addFieldList([
                'code',
                'label',
                'image',
                this._getSidAssociatiField()
            ]);
    }

    _getPontiSoppalchiNeutriInformationField() {
        return new Field('mc_ponti_e_soppalchi_neutri_information')
            .addFieldList([
                'code',
                'label',
                'image',
                this._getSidAssociatiField()
            ]);
    }

    _getLettiNeutriInformationField() {
        return new Field('mc_letti_neutri_information')
            .addFieldList([
                'code',
                'label',
                'image',
                this._getSidAssociatiField()
            ]);
    }

    _getScrivanieNeutreInformationField() {
        return new Field('mc_scrivanie_neutre_information')
            .addFieldList([
                'code',
                'label',
                'image',
                this._getSidAssociatiField()
            ]);
    }

    _getInfoMaterassoField() {
        return new Field('mc_info_materasso')
            .addField(new Field('att_materassi_img_right'))
            .addField(new Field('att_materassi_img_icon'))
            .addField(new Field('att_materassi_txt_consigli'))
            .addField(new Field('mc_matt_anallergico'))
            .addField(new Field('mc_matt_anallergico_label'))
            .addField(new Field('mc_matt_antiacaro'))
            .addField(new Field('mc_matt_antiacaro_label'))
            .addField(new Field('mc_matt_ecologico'))
            .addField(new Field('mc_matt_ecologico_label'))
            .addField(new Field('mc_matt_imbottitura'))
            .addField(new Field('mc_matt_imbottitura_label'))
            .addField(new Field('mc_matt_l_estivo'))
            .addField(new Field('mc_matt_l_estivo_label'))
            .addField(new Field('mc_matt_l_invernale'))
            .addField(new Field('mc_matt_l_invernale_label'))
            .addField(new Field('mc_matt_m_bonnell'))
            .addField(new Field('mc_matt_m_bonnell_label'))
            .addField(new Field('mc_matt_m_insacchettate'))
            .addField(new Field('mc_matt_m_insacchettate_label'))
            .addField(new Field('mc_matt_no_molle'))
            .addField(new Field('mc_matt_no_molle_label'))
            .addField(new Field('mc_matt_riciclabile'))
            .addField(new Field('mc_matt_riciclabile_label'))
            .addField(new Field('mc_matt_riv_cotone'))
            .addField(new Field('mc_matt_riv_cotone_label'))
            .addField(new Field('mc_matt_riv_trasp'))
            .addField(new Field('mc_matt_riv_trasp_label'))
            .addField(new Field('mc_matt_str_ortop'))
            .addField(new Field('mc_matt_str_ortop_label'));
    }

    _getSidAssociatiField() {
        return new Field('sid_associati')
            .addFieldList([
                'sid',
                'mish',
                'misl',
                'misp',
                'prezzo',
                'url'
            ]);
    }

    _getDiscountImageField() {
        return new Field('mc_flashsale')
            .addFieldList(this._getFlashSalesFields());
    }

    _getFlashSalesFields() {
        const discount_image = new Field('discount_image').addFieldList(['standard', 'xs']);
        return [
            'discount_code',
            discount_image,
            'items_total',
            'items_remaining',
            'time_start',
            'time_end',
            'is_coming',
            'cms_block',
            'is_closed',
            'closed_image'
        ];
    }

    _getBlackFridayCounterField() {
        if (this.options.args) {
            if (this.options.args.filter) {
                // eslint-disable-next-line no-magic-numbers, eqeqeq
                if (this.options.args.filter.categoryIds == 2001) {
                    const currentTimestamps = Date.now();
                    const roundedTimestamps = Math.round(currentTimestamps / (5 * 60 * 1000)) * (5 * 60 * 1000);

                    return new Field('mc_black_friday_counter')
                        .addFieldList(this._getBlackFridayCounterDetailFields())
                        .addArgument('moment', 'String', roundedTimestamps);
                }
            }
        }

        return new Field('mc_black_friday_counter')
            .addFieldList(this._getBlackFridayCounterDetailFields());
    }

    _getBlackFridayCounterDetailFields() {
        const discount_image = new Field('discount_image').addFieldList(['standard', 'xs']);
        return [
            'discount_code',
            discount_image,
            'items_total',
            'items_remaining',
            'time_start',
            'time_end',
            'is_coming',
            'cms_block',
            'is_closed',
            'closed_image'
        ];
    }

    _getStickersFields() {

    }

    _getStickersImageField() {
        const stickers_fields = [
            'cms_block',
            'content',
            'position'
        ];

        const category = new Field('category').addFieldList(stickers_fields);
        const flash_sales_slider = new Field('flash_sales_slider').addFieldList(stickers_fields);
        const second_level = new Field('second_level').addFieldList(stickers_fields);
        const third_level = new Field('third_level').addFieldList(stickers_fields);

        return new Field('mc_image_stickers')
            .addField(category)
            .addField(flash_sales_slider)
            .addField(second_level)
            .addField(third_level);
    }

    _getMultipriceField() {
        const mc_available_colors = new Field('mc_available_colors')
            .addFieldList([
                'sku',
                'mc_color',
                this._getColorInformationField()
            ])
            .addArgument('limit', 'Int', MAX_COLOR_LIMIT);

        return new Field('mc_multiprice')
            .addFieldList([
                'id',
                'sku',
                'name',
                'type_id',
                'stock_status',
                'url',
                'secondolivello',
                'mc_vendibile_sito',
                'mc_dimension_width',
                'mc_dimension_height',
                'mc_dimension_depth',
                'mc_inline_description',
                'mc_clicca_colori_presente',
                'mc_clicca_colori_testo',
                'mc_etichetta_colore',
                'mc_etichetta_colore_label',
                'catas',
                'cosmob',
                'mc_color',
                this._getFinitureColoriInformationField(),
                this._getTessutiColoriInformationField(),
                this._getColorInformationField(),
                this._getPriceRangeField(),
                mc_available_colors
            ]);
    }

    _getPriceRangeFields() {
        // Using an array as potentially would want to add maximum price
        return [
            this._getMinimalPriceField(),
            this._getMaximalPriceField(),
            this._getIsMigliorPrezzo()
        ];
    }

    _getPriceRangeField() {
        return new Field('price_range')
            .addFieldList(this._getPriceRangeFields());
    }

    _getProductLinkedFields() {
        return new Field('linkedProducts')
            .addFieldList([
                'sku',
                'name',
                'type_id',
                'mc_inline_description',
                'mc_dimension_width',
                'mc_dimension_height',
                'mc_dimension_depth',
                'url',
                'mc_vendibile_sito',
                'image_url',
                'varianttag_sorting',
                this._getProductSmallField(),
                this._getPriceRangeField(),
                this._getProductListingImagesField()
            ]);
    }

    _getColorImageField() {
        return new Field('image').addFieldList([
            'url'
        ]);
    }

    _getMcAvailableColorField(limit = null) {
        const field = new Field('mc_available_colors')
            .addFieldList([
                'sku',
                'name',
                'type_id',
                'mc_inline_description',
                'mc_dimension_width',
                'mc_dimension_height',
                'mc_dimension_depth',
                'mc_composizione_tipo',
                'mc_color',
                'url',
                'mc_vendibile_sito',
                'catas',
                'cosmob',
                this._getDiscountImageField(),
                this._getBlackFridayCounterField(),
                this._getProductThumbnailField(),
                this._getProductSmallField(),
                this._getPriceRangeField(),
                this._getColorInformationField(),
                this._getColorImageField(),
                this._getRataFindomesticInformationField()
            ]);

        if (limit) {
            field.addArgument('limit', 'Int', limit);
        }

        return field;
    }

    _getMcProductsSizesField() {
        return new Field('mc_products_sizes')
            .addFieldList([
                'label',
                'value'
            ]);
    }

    _getConfigurationsField() {
        return new Field('mc_configurations')
            .addFieldList([
                'sku',
                'name',
                'url',
                'price_delta',
                'final_price',
                'misure',
                'has_dishwasher',
                'elImage',
                'elName',
                'ElDesc'
            ]);
    }

    _getVariantsField() {
        // eslint-disable-next-line no-magic-numbers, eqeqeq
        if (this.options.args) {
            if (this.options.args.filter) {
            // eslint-disable-next-line no-magic-numbers
                if (this.options.args.filter.categoryIds == 2001) {
                    return new Field('mc_product_variants')
                        .addFieldList([
                            'sku',
                            'name',
                            'url',
                            'mc_inline_description',
                            'mc_dimension_width',
                            'mc_dimension_height',
                            'mc_dimension_depth',
                            'mc_techspecs_template',
                            'mc_vendibile_sito',
                            'catas',
                            'cosmob',
                            'is_soggiorno',
                            'varianttag_sorting',
                            'attribute_set',
                            this._getColorInformationField(),
                            this._getPriceRangeField(),
                            this._getProductSmallField(),
                            this._getDiscountImageField(),
                            this._getStickersImageField(),
                            this._getProductListingImagesField()
                        ]);
                }
            }
        }

        return new Field('mc_product_variants')
            .addFieldList([
                'sku',
                'name',
                'url',
                'mc_inline_description',
                'mc_dimension_width',
                'mc_dimension_height',
                'mc_dimension_depth',
                'mc_techspecs_template',
                'mc_vendibile_sito',
                'catas',
                'cosmob',
                'is_soggiorno',
                'varianttag_sorting',
                'attribute_set',
                this._getColorInformationField(),
                this._getPriceRangeField(),
                this._getProductSmallField(),
                this._getDiscountImageField(),
                this._getBlackFridayCounterField(),
                this._getStickersImageField(),
                this._getProductListingImagesField()
            ]);
    }

    _getProductListingImagesField() {
        return new Field('mc_product_listing_images')
            .addFieldList([
                'main',
                'mobile',
                'hover',
                'variant_image'
            ]);
    }

    _getNuovoTemplateField() {
        return new Field('nuovo_template_option')
            .addFieldList([
                'value',
                'label'
            ]);
    }

    _getIsMigliorPrezzo() {
        return new Field('miglior_prezzo');
    }

    _getRataFindomesticInformationField() {
        return new Field('mc_rata_findomestic_information')
            .addFieldList([
                'rata',
                'durata'
            ]);
    }

    _getCodeQTY() {
        return [
            'value'
        ];
    }

    _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
        const {
            isSingleProduct,
            noAttributes = false,
            noVariants = false,
            noVariantAttributes = false
        } = this.options;

        const fields = [
            'id',
            'sku',
            'name',
            'type_id',
            'stock_status',
            'mc_inline_description',
            'mc_inline_description2',
            'mc_inline_description3',
            'mc_etichetta_finitura',
            'mc_dimension_height',
            'mc_dimension_width',
            'mc_dimension_depth',
            'mc_clicca_colori_presente',
            'mc_clicca_colori_testo',
            'mc_etichetta_colore',
            'mc_etichetta_colore_label',
            'mc_composizione_tipo',
            'mc_vendibile_sito',
            'mc_multiprice_title',
            'mc_hover_image',
            'mc_color_logo',
            'mc_piani_di_lavoro',
            'mc_finiture_colori',
            'mc_tipologie_cucine',
            'mc_maniglie',
            'mc_meccanismi',
            'mc_elettrodomestici',
            'mc_tessuti_colori',
            'mc_complementi',
            'mc_modelli',
            'mc_armadi_neutri',
            'mc_ponti_e_soppalchi_neutri',
            'mc_letti_neutri',
            'mc_scrivanie_neutre',
            'secondolivello',
            'mc_color',
            'catas',
            'descrizione_completa',
            'cosmob',
            'nuovo_template',
            'camere_giroletto',
            'camere_giroletto_link',
            'desc_finiture_colori',
            'desc_elettrodomestici',
            'desc_maniglie',
            'desc_meccanismi',
            'desc_piani_lavoro',
            'desc_tessuti_colori',
            'desc_complementi',
            'desc_modelli',
            'desc_tipologie',
            'logo_elettrodomestico',
            'video_assembly',
            'mc_config',
            'flusso_varianti',
            'sid_elettrodomestico',
            'attribute_set',
            'desc_camere_complete',
            'placeholder_text',
            'has_dishwasher',
            'is_soggiorno',
            'mc_modello',
            'mc_componenti_camere',
            'desc_versioni_prodotto'
        ];

        fields.push(
            this._getSidAbbinatiField(),
            this._getNuovoTemplateField(),
            this._getProductLinkedFields(),
            this._getCollezioneCamereInformationField(),
            this._getDishWasherInformationField(),
            this._getCamereCompleteInformationField(),
            this._getElettrodomesticiBloccatiInformationField(),
            this._getTipologieCucineInformationField(),
            this._getVersioniProdottoInformationField(),
            this._getInterniArmadiInformationField(),
            this._getVersioniProdottoTwoInformationField(),
            this._getComponentiCamereInformationField(),
            this._getDiscountImageField(),
            this._getBlackFridayCounterField(),
            this._getMcProductsSizesField(),
            this._getStickersImageField(),
            this._getMultipriceField(),
            this._getPriceRangeField(),
            this._getColorInformationField(),
            this._getFinitureColoriInformationField(),
            this._getBoxMosaicoField(),
            this._getManiglieInformationField(),
            this._getPianiDiLavoroInformationField(),
            this._getElettrodomesticiInformationField(),
            this._getMeccanismiInformationField(),
            this._getTessutiColoriInformationField(),
            this._getComplementiInformationField(),
            this._getModelliInformationField(),
            this._getArmadiNeutriInformationField(),
            this._getPontiSoppalchiNeutriInformationField(),
            this._getLettiNeutriInformationField(),
            this._getScrivanieNeutreInformationField(),
            this._getProductThumbnailField(),
            this._getProductSmallField(),
            this._getProductImageField(),
            this._getProductListingImagesField(),
            this._getShortDescriptionField(),
            this._getMcAvailableColorField(!isSingleProduct ? MAX_COLOR_LIMIT : null),
            this._getRataFindomesticInformationField(),
            this._getInfoMaterassoField(),
            this._getCategoriesField()
        );

        // if it is normal product and we need attributes
        // or if, it is variant, but we need variant attributes or variants them-self
        if ((!isVariant && !noAttributes) || (isVariant && !noVariantAttributes && !noVariants)) {
            fields.push(this._getAttributesField(isVariant));
        }

        // to all products (non-variants)
        if (!isVariant) {
            fields.push(
                'url',
                this._getReviewCountField(),
                this._getRatingSummaryField()
            );

            // if variants are not needed
            if (!noVariants) {
                fields.push(
                    this._getConfigurableProductFragment(),
                    this._getBundleProductFragment()
                );
            }
        }

        // prevent linked products from looping
        if (isForLinkedProducts) {
            fields.push(this._getProductLinksField());
        }

        // additional information to PDP loads
        if (isSingleProduct) {
            fields.push(this._getVariantsField());
            fields.push('brand_flixmedia');
            fields.push('mc_code_ean');
            fields.push('mc_techspecs_template');
            fields.push('gar5anni');
            fields.push('gar10anni');
            fields.push('gar15anni');
            fields.push('mc_national_carrier');
            fields.push('mc_venditacc_tablet');
            fields.push('mc_trasporto_montaggio');
            fields.push('mc_ritiro_cliente');
            fields.push('meta_title');
            fields.push('meta_keyword');
            fields.push('canonical_url');
            fields.push('meta_description');
            fields.push(this._getConfigurationsField());

            fields.push(
                'stock_status',
                this._getDescriptionField(),
                this._getMediaGalleryField(),
                this._getSimpleProductFragment(),
                this._getProductLinksField(),
                this._getCustomizableProductFragment()
            );
            fields.push('promo_contatore');

            // for variants of PDP requested product
            if (!isVariant) {
                fields.push(
                    // this._getCategoriesField(),
                    this._getReviewsField(),
                    this._getVirtualProductFragment(),
                    this._getCustomizableProductFragment()
                );
            }
        }

        return fields;
    }
}

export default new ProductListQuery();
